import Pusher from "pusher-js";
import React from "react";
import {
  useGetStreamQuery,
  useGetStreamTokenQuery,
} from "../services/agoraService";
import { baseUrl } from "../services/baseQueries";
import moment from "moment";

const appKey = "a924587349347e93fbc5";
const cluster = "us3";

const guestId = Math.floor(Math.random() * (1000000 - 10000 + 1) + 10000);

export const StreamContext = React.createContext();

const StreamProvider = ({ children }) => {
  const [channelName, setChannelName] = React.useState("");

  // audio stream related state and hooks

  const [agoraClient, setAgoraClient] = React.useState(null);
  const [isInitialising, setIsInitialising] = React.useState(true);
  const [remoteUser, setRemoteUser] = React.useState({});
  const [remoteUsers, setRemoteUsers] = React.useState([]);

  const { data: streamTokenData } = useGetStreamTokenQuery(
    {
      channelName: channelName.toLowerCase(),
      guestId,
    },
    { skip: !(channelName && guestId) }
  );
  const { data: stream } = useGetStreamQuery(channelName.toLowerCase(), {
    pollingInterval: 1000,
    skip: channelName === "",
  });

  // comment stream related state and logic

  const [commentChannel, setCommentChannel] = React.useState(null);
  const [comments, setComments] = React.useState([]);
  const [transcripts, setTranscripts] = React.useState([]);

  const getCommentsHistory = async () => {
    try {
      const activeSessionId = stream?.data?.activeSession?.Id;

      const { data: result } = await (
        await fetch(`${baseUrl}/agora/sessions/${activeSessionId}/comments`)
      ).json();
      if (!result) {
        return;
      }
      const sortedComments = result.sort((a, b) => {
        return new Date(a.CreatedDateTime) - new Date(b.CreatedDateTime);
      });
      // Format the comments
      const formattedComments = sortedComments.map((_item) => {
        if (Number(_item.SenderId) === stream.data.UserId) {
          // Comment from the host
          return {
            ..._item,
            type: "INCOMING",
            body: _item.Payload,
            timestamp: moment(_item.CreatedDateTime).unix(),
          };
        }
        const payload = JSON.parse(_item.Payload);
        if (payload.type === "event:hay") {
          // Comment about hay update
          return {
            ..._item,
            type: "HAY",
            amount: payload.body.amount,
            timestamp: moment(_item.CreatedDateTime).unix(),
            body: `Tipped ${payload.body.amount} hay`,
          };
        }

        // Other types of comments
        return {
          ..._item,
          type: "INCOMING",
          body: payload.body,
          timestamp: moment(_item.CreatedDateTime).unix(),
        };
      });

      // Set the formatted comments as the messages copy
      setComments(formattedComments);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!stream) {
      return;
    }
    const activeSessionId = stream?.data?.activeSession?.Id;
    if (!activeSessionId || transcripts.length > 0) {
      return;
    }
    const getInitialTranscript = async () => {
      const { data } = await (
        await fetch(
          `${baseUrl}/agora/sessions/${activeSessionId}/transcriptions`
        )
      ).json();
      handleUpdateTranscript(data);
    };

    getInitialTranscript();
    getCommentsHistory();
  }, [stream]);

  const handleUpdateTranscript = (newTranscript) => {
    setTranscripts((state) => {
      if (state.length === 0) {
        if (Array.isArray(newTranscript)) {
          return newTranscript;
        }
        return [newTranscript.body];
      }
      const lastTranscript = state.pop();
      if (lastTranscript?.userId == newTranscript.body.userId) {
        return [...state, newTranscript.body];
      }
      return [...state, lastTranscript, newTranscript.body];
    });
  };

  const subscribeToChannel = (pusherInstance, channelName) => {
    const channel = pusherInstance.subscribe(`presence-${channelName}`);
    setCommentChannel(channel);

    channel.bind("message", (data, user) => {
      let newMessage = data;
      if (newMessage.type === "chat:message") {
        setComments((oldComment) => [...oldComment, newMessage]);
      }
      if (newMessage.type === "event:transcript") {
        handleUpdateTranscript(newMessage);
      }
    });

    channel.bind("client-message", (data, user) => {
      let newMessage = data;
      if (newMessage.type === "chat:message") {
        setComments((oldComment) => [...oldComment, newMessage]);
      }
      if (newMessage.type === "event:transcript") {
        handleUpdateTranscript(newMessage);
      }
    });
  };

  /**
   * Rollsback state to inital values.
   */
  const initializeCommentStream = () => {
    setCommentChannel(null);
    setComments([]);
    setTranscripts([]);
  };

  React.useEffect(() => {
    let pusherInstance = null;
    if (channelName) {
      pusherInstance = new Pusher(appKey, {
        cluster,
        authEndpoint: `${baseUrl}/pusher/streams/${channelName}/guest/pusher-token/?guestId=${
          channelName + Math.floor(1000 + Math.random() * 9000)
        }`,
      });
      subscribeToChannel(pusherInstance, channelName);
    } else {
      initializeCommentStream();
    }

    return () => {
      if (channelName) {
        pusherInstance.unsubscribe(`presence-${channelName}`);
      }
    };
  }, [channelName]);

  const changeChannelName = (newChannelName = "") => {
    newChannelName = newChannelName.toLowerCase();
    if (newChannelName && newChannelName !== channelName) {
      setChannelName(newChannelName);
    }
  };

  // audio stream related logic

  React.useEffect(() => {
    if (window.AgoraRTC) {
      handleCreateClient();
    }
  }, [window.AgoraRTC]);

  React.useEffect(() => {
    if (agoraClient && streamTokenData) {
      handleInit(channelName, streamTokenData.rtcToken);
    }

    return () => {
      if (agoraClient) {
        agoraClient.leave();
      }
    };
  }, [agoraClient, streamTokenData]);

  const handleCreateClient = async () => {
    const client = await window.AgoraRTC.createClient({
      mode: "live",
      codec: "vp8",
      role: "audience",
    });
    setAgoraClient(client);
  };

  const subscribe = async (mediaType) => {
    // subscribe the remote user
    await agoraClient.subscribe(remoteUser, mediaType);
    remoteUser.audioTrack.play();
  };

  const unsubscribe = async (mediaType) => {
    // unsubscribe the remote user
    await agoraClient.unsubscribe(remoteUser, mediaType);
  };

  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    setRemoteUser(user);
    setRemoteUsers([...remoteUsers].push(id));
    setIsInitialising(false);
  };

  const handleUserUnpublished = (user, mediaType) => {
    const id = user.uid;
    const index = remoteUsers.indexOf(id);
    remoteUsers.splice(index, 1);
  };

  const handleInit = async (channelName, token) => {
    agoraClient.on("user-published", handleUserPublished);
    agoraClient.on("user-unpublished", handleUserUnpublished);
    await agoraClient.join(
      "4880e7f0ad5f428c9547f12d6bc115d7",
      channelName,
      token,
      guestId
    );
  };

  return (
    <StreamContext.Provider
      value={{
        stream,
        commentStream: {
          commentChannel,
          comments,
          transcripts,
          changeChannelName,
        },
        audioStream: {
          isInitialising,
          setIsInitialising,
          subscribe,
          unsubscribe,
        },
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export default StreamProvider;
