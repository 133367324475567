import React from "react";
import "./comment.scss";
import moment from "moment";

function Comment({ comment }) {
  return (
    <>
      {comment.body && comment.user && (
        <div className="d-flex justify-content-start my-2">
         
          {comment.user.avatarUrl ? (
            <img
              src={comment.user.avatarUrl}
              alt="user image"
              className="rounded-circle "
              style={{
                width: "40px",
                minWidth: "40px",
                height: "40px",
                marginRight: 10,
              }}
            />
          ) : (
            <div
              className="rounded-circle"
              style={{
                width: "40px",
                minWidth: "40px",
                height: "40px",
                marginRight: 10,
                backgroundColor: "#8e8e93",
              }}
            >
              <p
                className="text-center"
                style={{
                  fontSize: 20,
                  lineHeight: "40px",
                  color: "white",
                }}
              >
                {comment.user.name[0] ?? "U"}
              </p>
            </div>
          )}
          <div className="mx-2 p-2 w-100 position-relative">
            <div
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#c4c5c6",
              }}
            >
              {comment.user.name}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
                color: comment.type === "HAY" ? "#FFD700" : "#fff",
              }}
            >
              {comment?.body}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#C4C5C6",
                position: "absolute",
                right: "0.5rem",
                top: "0.5rem",
              }}
            >
              {moment.unix(comment.timestamp).fromNow()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Comment;
